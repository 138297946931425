import React from "react"
import { Container, Row } from "reactstrap"
import Layout from "../containers/Layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () =>
    <Layout>
        <SEO title="Terms" />
        <div className="main">
            <div className="section text-center">
                <div className="mt-5 p-4 flex" style={{ backgroundColor: "#d3d3d36e" }} >
                    <Container>
                        <Row >
                            <h3 className="mt-0">سياسة الخصوصية للبوابة البيئية الخليجي</h3>
                        </Row>
                    </Container>
                </div>
                <br />
                <Container style={{ textAlign: "right" }}>
                    <Row className="mb-2">
                        <p>تحرص البوابة البيئية لدول مجلس التعاون الخليجي على ضمان سرية معلومات مستخدميها وزوارها وتضعها على رأس قائمة الأولويات، وتبذل إدارة البوابة كل جهودها لتقديم خدمة ذات جودة عالية لكل المستفيدين.
                                    يجب على زوار البوابة والمستفيدين من خدماتها الاطلاع المستمر على شروط ومبادئ الخصوصية وسرية المعلومات لمعرفة أية تحديثات تتم عليها، علماً بأن إدارة البوابة غير مطالبة بالإعلان عن أية تحديثات تتم على تلك الشروط والمبادئ، ويعني استخدامك للبوابة اطلاعك وموافقتك على تلك الشروط والمبادئ وما يتم عليها من تعديلات مستمرة.</p>
                    </Row>
                    <Row className="mb-1">
                        <h4>أمن المعلومات الشخصية </h4>
                    </Row>
                    <Row className="mb-2">
                        <p>تقوم إدارة البوابة باتخاذ الإجراءات والتدابير المناسبة والملائمة للمحافظة على المعلومات الشخصية التي لديها بشكل آمن يضمن حمايتها من الفقدان أو الدخول غير المصرح به أو إساءة الاستخدام، أو التعديل والإفصاح غير المصرح بهما، ومن أهم التدابير المعمول بها في البوابة لحماية معلومات الزائر الشخصية:
                                <br />
                            <li>الإجراءات والتدابير المشددة لحماية أمن المعلومات والتقنية التي نستخدمها للوقاية من عمليات الاحتيال والدخول غير المصرح به إلى أنظمتنا.</li>
                            <li>التحديث المنتظم والدوري لإجراءات وضوابط الحماية التي تفي أو تزيد عن المعايير القياسية.</li>
                            <li>توافر موظفين مؤهلين ومدربين على احترام سرية المعلومات الشخصية لزائرينا.</li>
                        </p>
                    </Row>

                    <Row className="mb-1">
                        <h4>جمع المعلومات الشخصية</h4>
                    </Row>
                    <Row>
                        <p>بمجرد زيارة المستخدم للبوابة البيئية الخليجية، يقوم الخادم الخاص بتسجيل عنوان بروتوكول شبكة الإنترنت IP الخاص بالمستخدم وتاريخ ووقت الزيارة والعنوان URL الخاص بأي موقع إلكتروني تتم منه إحالتك إلى البوابة.
                                <br />
                        </p>
                        <p>
                            تضع معظم المواقع الإلكترونية بمجرد أن تتم زيارتها ملفاً صغيراً على القرص الصلب الخاص بجهاز الزائر (المتصفح)، ويسمى هذا الملف "كوكيز" (Cookies)، وملفات الكوكيز عبارة عن ملفات نصية، تقوم بعض المواقع التي تزورها بإيداعها على القرص الصلب في جهازك، وتحتوي هذه الملفات النصية على معلومات تتيح للموقع الذي أودعها أن يسترجعها عند الحاجة لها خلال زيارة المستخدم المقبلة للموقع ومن هذه المعلومات المحفوظة:
                                    <li>تذكر اسم مستخدم وكلمة مرور</li>
                            <li>حفظ إعدادات الصفحة في حال كان ذلك متاح على البوابة.</li>
                            <li>عدم إتاحة إمكانية التصويت أكثر من مرة لنفس المستخدم.</li>
                        </p>
                    </Row>
                    <Row>
                        <p>إذا قمت باستخدام تطبيق مباشر أو أرسلت لنا بريداً إلكترونيًا عبر البوابة تزودنا فيه ببيانات شخصية، فإننا قد نتقاسم البيانات الضرورية مع جهات أو إدارات أخرى، وذلك لخدمتك بصورة أكثر فعالية. ولن نتقاسم بياناتك الشخصية مع الجهات غير الحكومية إلا إذا كانت من الجهات المصرح لها من الجهات المختصة بالقيام بأداء خدمات حكومية محددة.</p>
                        <p>وبتقديمك لبياناتك ومعلوماتك الشخصية من خلال البوابة البيئية الخليجية، فإنك توافق تمامًا على تخزين ومعالجة واستخدام تلك البيانات من قبل السلطات في دول مجلس التعاون لدول الخليج العربية</p>
                        <p>ونحن نحتفظ بالحق في كل الأوقات في كشف أي معلومات للجهات المختصة، عندما يكون ذلك ضروريا للالتزام بأي قانون أو نظام أو طلب حكومي.</p>
                    </Row>

                    <Row className="mb-1">
                        <h4>حماية خصوصيتك</h4>
                    </Row>
                    <Row className="mb-2">
                        <p>لكي نتمكن من مساعدتك في حماية معلوماتك الشخصية فإننا نوصي بما يلي:
                                    <li>الاتصال بنا بشكل فوري عندما تظن أن شخصاً ما استطاع الحصول على كلمة السر الخاصة بك، أو رمز الاستخدام، أو الرقم السري، أو أي معلومات سرية أخرى.</li>
                            <li>لا تعط معلومات سرية عبر الهاتف أو شبكة الإنترنت ما لم تعرف هوية الشخص أو الطرف المستقبل للمعلومة.</li>
                            <li>استخدم متصفح آمن عند قيامك بإنجاز المعاملات عبر الإنترنت مع إغلاق التطبيقات غير المستخدمة على الشبكة، وتأكد من أن برنامج الحماية من الفيروسات محدث على الدوام.</li>
                        </p>
                    </Row>
                    <Row>
                        <p>هذه البوابة قد تحتوي على روابط إلكترونية لمواقع أو بوابات قد تستخدم طرقًا لحماية المعلومات وخصوصياتها تختلف عن الطرق المستخدمة لدينا. ونحن غير مسئولين عن محتويات وطرق وخصوصيات هذه المواقع الأخرى، وننصحك بالرجوع إلى إشعارات الخصوصية الخاصة بتلك المواقع.</p>
                    </Row>

                    <Row className="mt-2">
                        <h4>إرسال الرسائل الإلكترونية إلى البوابة البيئية الخليجية:</h4>
                    </Row>
                    <Row>
                        <p>عندما تقوم بالاستفسار أو طلب معلومات أو خدمة محددة أو في حالة قيامك بإعطاء معلومات إضافية مستخدما أياً من وسائل الاتصال مع بالبوابة البيئية الخليجية سواء كانت إلكترونية أو غير إلكترونية، مثل طلب الاستفسار على موقعنا، فإننا سنستخدم عنوان بريدك الإلكتروني للرد على استفساراتك، كما أنه من الممكن حفظ عنوان بريدك ورسالتك وإجابتنا عليها لأغراض مراقبة الجودة، كما أننا قد نقوم بذلك للغايات القانونية والرقابية.
                                </p>
                    </Row>

                </Container>
            </div>
        </div>
    </Layout >



export default PrivacyPolicyPage
